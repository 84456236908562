import { Link } from 'react-router-dom';


function Menu() {
  return (
    <div className="menu-componenet">
      <Link className= "thumbnail-component" to="/deleteAccount">
        <div>Delete Account</div>
      </Link>
      <br/>
      <Link className= "thumbnail-component" to="/forgotPassword">
        <div>Lost Password</div>
      </Link>
      <br/>
      <Link className= "thumbnail-component" to="/">
        <div>Change Password</div>
       </Link>
    </div>
  );
}

export default Menu;
