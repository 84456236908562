import Header from "./Header.js";

import Menu from "./Menu.js"

function TarotNexus() {
  return (
    <>
    <Header />
    <div className="below-header-component">        
    <div className="main-component">
      
            <h2>Tarot Nexus.</h2>
                <h6>
                This page allows the management of your Tarot Nexus account information. 
                </h6>
            
      
      </div>
    <Menu />
    </div>
  </>
  );
}

export default TarotNexus;
