import React, { useState } from "react";

function DeleteForm() {
  // Define state variables to store form input values
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Define the API endpoint URL
    const apiUrl = "https://app.holybee.net/api/deleteAccount";

    try {
      // Send a POST request to the server
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Reset the form after successful submission
        setFormData({
          username: "",
          password: "",
        });

        const responseData = await response.json();

        // Now you can access properties from the JSON data
        console.log(response);
        console.log("status:", responseData.status);
        console.log("message:", responseData.message);

        alert("Account successfully deleted.");
      } else {
        alert("Failed to delete account.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>

        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default DeleteForm;
