import Header from "./Header.js";
import Menu from "./Menu.js";
import ForgotForm from "./ForgotForm.js";


function ForgotPassword() {
  return (
    <>
    <Header />
    <div className="below-header-component">        
      <div className="main-component">
        
        <h2>Reset Your Account Password.</h2>
        <h6>
          This will send you an email to change your password.
        </h6>
        <ForgotForm />
      
     
    </div>
    <Menu />
    </div>
  </>
  );
}

export default ForgotPassword;