import Header from "./Header.js";
import DeleteForm from "./DeleteForm.js";
import Menu from "./Menu.js"

function DeleteAccount() {
  return (
    <>
    <Header />
    <div className="below-header-component">        
    <div className="main-component">
      
            <h2>Delete Your Account Information.</h2>
                <h6>
                This will delete your account and all of your stored data. You will
                lose any owned inapp purchases (coins). Be very sure you want to do
                this. It cannot be undone.
                </h6>
            <DeleteForm />
      
      </div>
    <Menu />
    </div>
  </>
  );
}

export default DeleteAccount;
