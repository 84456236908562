import './App.css'
import React from "react";
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import DeleteAccount from "./components/DeleteAccount.js";
import ForgotPassword from "./components/ForgotPassword.js";
import ResetPassword from "./components/ResetPassword.js";
import TarotNexus from './components/TarotNexus';
import Admin from "./components-admin/Admin.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/admin.html" element={<Admin />} />
        <Route path="/deleteAccount" element={<DeleteAccount />} />
        <Route path="/forgotPassword" element={<ForgotPassword />}/>
        <Route path="/resetPassword" element={<Outlet />}>
          <Route path=":token" element={<ResetPassword />}/>
          <Route index element={<div>No Token</div>}/>
        </Route>
        <Route path="/" element={<TarotNexus />}/>
        <Route path="*" element={<div>Not Found</div>}/>
        
      </Routes>
    </Router>
  );
}

export default App;
