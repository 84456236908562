

function Header() {
  return (
    <header className="header-component">
    
      <div>
        <img src="/tarot_logo_white.png" alt="logo" />
      </div>
      <div >
        <h1>Tarot Nexus</h1>
      </div>
      
    </header> 
  );
}

export default Header;
