import { useParams } from 'react-router-dom';
import Header from "./Header.js";
import Menu from "./Menu.js";
import ResetForm from "./ResetForm.js";

function ResetPassword() {
  const { token } = useParams();

  return (

      <>
      <Header />
      <div className="below-header-component">        
        <div className="main-component">
          
        <div>
        <h2>Enter your new Password.</h2>
        <p>
          
        </p>
        <ResetForm token={token}/>
        </div>
      </div>
      <Menu />
      </div>
    </>
    );
  }


export default ResetPassword;