import Header from "./Header.js";
import Menu from "./Menu.js";

function Admin() {
  return (
    <>
    <Header />
    <div className="below-header-component">        
    <div className="main-component">    
        <p>This is the body</p>
    </div>
    <Menu />
    </div>
  </>
  );
}
export default Admin;
