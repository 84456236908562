import { Link } from 'react-router-dom';


function Menu() {
  return (
    <div className="menu-componenet">
      <Link className= "thumbnail-component" to="/">
        <div>Create Account</div>
      </Link>
      <br/>
      <Link className= "thumbnail-component" to="/">
        <div>Delete Account</div>
      </Link>
      <br/>
      <Link className= "thumbnail-component" to="/">
        <div>Search</div>
       </Link>
       <br/>
       <Link className= "thumbnail-component" to="/">
        <div>Stats</div>
       </Link>
    </div>
  );
}

export default Menu;
