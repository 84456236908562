import React, { useState } from "react";


function ForgotForm() {
  // Define state variables to store form input values
  const [formData, setFormData] = useState({
    username: "",
    email: "",
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

// Email validation regular expression
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

// Check if the email is in a valid format
if (!formData.email.match(emailRegex)) {
  alert("Please enter a valid email address.");
  return; // Exit the function if the email is invalid
}

    // Define the API endpoint URL
    
    const apiUrl = "https://app.holybee.net/api/forgotPassword";
    // "http://localhost:5000/api/forgotPassword"; // "https://app.holybee.net/api/forgotPassword";

    try {
      // Send a POST request to the server
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Reset the form after successful submission
        setFormData({
          username: "",
          email: "",
        });

        const responseData = await response.json();

        // Now you can access properties from the JSON data
        console.log(response);
        console.log("status:", responseData.status);
        console.log("message:", responseData.message);

        alert("Email Sent.");
      } else {
        alert("Failed to to send email.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Username:</label>
          <input
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
            
          />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            
          />
        </div>

        <div>
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default ForgotForm;
