import React, { useState, useEffect } from "react";


function ResetForm({token}) {
  // Define state variables to store form input values

  const [passwordMatch, setPasswordMatch] = useState(true);

  const [formData, setFormData] = useState({
    password: "",
    password2: "",
    token:token,
  });

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData ({
      ...formData,
      [name]: value,
    });
  };
    useEffect(() => {
      if (formData.password !== formData.password2) {
        setPasswordMatch(false);
      } else {
        setPasswordMatch(true);
      }
    }, [formData.password, formData.password2]);
   

  

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

  // Check if passwords match

  if (!passwordMatch) {
    alert("Passwords do not match. Please correct them.");
    return;
  }

    // Define the API endpoint URL

    const apiUrl = "https://app.holybee.net/api/resetPassword";
    // "http://localhost:5000/api/resetPassword"; // "https://app.holybee.net/api/resetPassword";

    try {
      // Send a POST request to the server
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      // Check if the request was successful (status code 200)
      if (response.status === 200) {
        // Reset the form after successful submission
        setFormData({
          password: "",
          password2: "",
          token: "",
        });

        const responseData = await response.json();

        // Now you can access properties from the JSON data
        console.log(response);
        console.log("status:", responseData.status);
        console.log("message:", responseData.message);

        alert("Password changed.");
      } else {
        alert("Failed to change password.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while submitting the form.");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Password:</label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </div>
        <div>
          <label htmlFor="password2">Re-enter password:</label>
          <input
            type="password"
            id="password2"
            name="password2"
            value={formData.password2}
            onChange={handleInputChange}
            required
          />
          
        </div>

        <div>
          <button type="submit" disabled={!passwordMatch}>
            Submit
            </button>
            {!passwordMatch && <p style={{ color: "red" }}>Passwords do not match.</p>}
        </div>
      </form>
    </div>
  );
}

export default ResetForm;
