function Header() {
  return (
    <header className="header-component">
      <div>
        <img src="/logo192.png" className="App-logo" alt="logo" />
      </div>
      <div className="App-header-text ">
        <h1>Tarot Nexus</h1>
      </div>
    </header>
  );
}
export default Header;
